import React from 'react'
import { graphql } from 'gatsby'
import Content from '../components/Content.js'
//import PageHeader from '../components/PageHeader'
import Layout from '../components/Layout.js'
import BackgroundVideo from '../components/BackgroundVideo'
import Donate from '../components/Donate'
import '../components/Footer.css'

// Export Template for use in CMS preview
export const VideoPageTemplate = ({
  title,
  section2,
  videoPoster,
  video,
  donatevalues,
  calltoaction,
  paidfor
}) => (
  <main>
    <div>
     {/* <Donate items={donatevalues} /> */}
     </div>
      <div className="container taCenter">
        <Content source={section2} />
      </div>
    <div className="container taCenter">
      <span className="paid">
      {paidfor}
      </span>
    </div>
    {/* <section className="BackgroundVideo-section section">
      <BackgroundVideo poster={videoPoster}>
        {video && <source src={video} type="video/mp4" />}
      </BackgroundVideo>
      {calltoaction}
    </section> */}
  </main>
)

const VideoPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <VideoPageTemplate {...page} {...page.frontmatter} body={page.html} />
  </Layout>
)

export default VideoPage

export const pageQuery = graphql`
  query VideoPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      ...Gallery
      html
      frontmatter {
        title
        template
        subtitle
        section2
        videoPoster
        video
        donatevalues
        calltoaction
        paidfor
      }
    }
  }
`
